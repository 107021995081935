import { $ } from '../utils/query-selector.js'
import Project from '../components/project.js'

let galleries = []
let oldGalleries = []

export default {
  namespace: 'project',
  beforeEnter({ next }) {
    oldGalleries = [...galleries]
    galleries = []

    const $project = $('.js-project', next.container)

    if (!$project) {
      return
    }

    galleries.push(new Project($project))
  },
  beforeLeave() {
    for (const g of galleries) {
      g.togglePhotoIndex(false)
    }

    galleries = []
  },
  afterEnter() {
    for (const g of oldGalleries) {
      g.destroy()
    }

    galleries = []
  },
}
