import { $, $$ } from '../utils/query-selector.js'

export default class ProjectsScroller {
  constructor(el) {
    this.frozen = false
    this.$el = el
    this.$list = $('.js-projects-scroller__list', this.$el)
    this.$$items = $$('.js-projects-scroller__project', this.$el)
    this.$$ghosts = $$('.js-projects-scroller__title-ghost', this.$el)
    this.$$thumbnails = $$('.js-projects-scroller__thumbnail', this.$el)

    // Init scroll effects
    this.scrollEffectFrame = null
    this.handleScroll = () => {
      cancelAnimationFrame(this.scrollEffectFrame)
      this.scrollEffectFrame = requestAnimationFrame(() => {
        this.update()
      })
    }

    this.$el.addEventListener('click', (e) => {
      const $target = e.target.closest('.js-projects-scroller__project');

      if (!$target) {
        return;
      }

      $('a', $target).click()
    })

    this.isProjectsVisible = null
    this.currentProject    = null

    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleScroll)
    this.update()
  }

  update() {
    if (this.frozen) {
      return
    }

    this.updateSpecs()
    this.updateProjectsSection()
    this.updateCurrentProject()
    this.updateEffects()
  }

  freeze() {
    cancelAnimationFrame(this.scrollEffectFrame)
    this.frozen = true
  }

  updateCurrentProject() {
    let index = this.visibleAreas.indexOf(Math.max(...this.visibleAreas))

    if (index !== this.currentProject) {
      if (this.currentProject !== null) {
        this.$$items[this.currentProject].dataset.current = 'false'
      }
      this.$$items[index].dataset.current = 'true'
      this.$el.dataset.ghostColor = this.$$items[index].dataset.ghostColor
      this.currentProject = index
    }
  }

  updateEffects() {
    this.$$items.forEach((item, index) => {
      item.style.setProperty('--progress', this.scrollProgress[index])

      let ghostSpec = this.ghostSpecs[index]
      let thumbSpec = this.thumbSpecs[index]

      // title ghost mask
      let t = thumbSpec.top - ghostSpec.top
      let r = ghostSpec.right - thumbSpec.right
      let b = ghostSpec.bottom - thumbSpec.bottom
      this.$$ghosts[index].style.setProperty('clip-path', `inset(${t}px ${r}px ${b}px 0)`)
    })
  }

  updateProjectsSection() {
    this.$el.dataset.inview = this.isProjectsInViewport.toString()
  }

  updateSpecs() {

    this.viewportStart = window.scrollY
    this.viewportEnd   = this.viewportStart + window.innerHeight

    // determine projects section visibility

    this.listSpec     = this.$list.getBoundingClientRect()
    this.thumbSpecs   = this.$$thumbnails.map((item) => item.getBoundingClientRect())
    this.projectSpecs = this.$$items.map((item) => item.getBoundingClientRect())
    this.ghostSpecs   = this.$$ghosts.map((item) => item.getBoundingClientRect())

    this.isProjectsBelowViewport = this.listSpec.top > (window.innerHeight - this.projectSpecs[0].height) / 2
    this.isProjectsAboveViewport = window.innerHeight - this.listSpec.bottom > (window.innerHeight - this.projectSpecs[this.$$items.length - 1].height) / 2
    this.isProjectsInViewport = !this.isProjectsBelowViewport && !this.isProjectsAboveViewport


    this.visibleAreas   = []
    this.scrollProgress = []

    this.projectSpecs.forEach((item, index) => {
        let elSpec  = this.projectSpecs[index]
        let elStart = this.viewportStart + elSpec.top
        let elEnd   = elStart + elSpec.height

        let progress
        let visibleArea = Math.max(0, (Math.min(elEnd, this.viewportEnd) - Math.max(elStart, this.viewportStart)) / window.innerHeight)

        if (visibleArea > 0) {
          let scrollMin = elStart - window.innerHeight
          let scrollMax = elEnd
          progress = 1 - (scrollMax - this.viewportStart) / (scrollMax - scrollMin)
        } else if (elEnd > this.viewportStart) {
          progress = 0
        } else {
          progress = 1
        }

        this.visibleAreas.push(visibleArea)
        this.scrollProgress.push(progress)
      })
  }

  destroy() {
    cancelAnimationFrame(this.scrollEffectFrame)
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleScroll)
  }
}
