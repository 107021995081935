import { $ } from "../utils/query-selector.js";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.tz.setDefault("Europe/Madrid");

const TIMEZONES = {
  "GMT+1": "CET",
  "GMT+2": "CEST",
}

let updateLocalTimeInterval = null;

export default {
  namespace: "about",
  beforeEnter({ next }) {
    const localTime = $(".js-about__local-time", next.container);

    if (localTime) {
      const updateLocalTime = () => {
        const d = dayjs();
        const time = d.format("HH:mm");
        let timezone = d.format("z");
        timezone = TIMEZONES[timezone] || timezone;
        const offset = d.format("Z").replace(/^([+-])0*([0-9]+):.*$/, "$1$2");
        localTime.innerHTML = `${time} (${timezone} / UTC ${offset})`;
        localTime.setAttribute("datetime", d.format("YYYY-MM-DDTHH:mm:ssZ"));
      }

      updateLocalTime();
      updateLocalTimeInterval = setInterval(updateLocalTime, 1000);
    }
  },
  afterLeave() {
    if (updateLocalTimeInterval) {
      clearInterval(updateLocalTimeInterval);
    }
  }
};
