import { $, $$ } from "../utils/query-selector.js";
import { fadeIn, fadeOut } from "../utils/animations.js";
import zenscroll from "zenscroll";

export default {
  name: "project-to-project-transition",

  from: {
    namespace: "project",
  },
  to: {
    namespace: "project",
  },

  async leave({ current, next }) {
    const $prevLink = $("a[rel=prev]", current.container);
    const $nextLink = $("a[rel=next]", current.container);

    fadeOut($$(".js-project-nav-link", current.container))

    if ($prevLink && $prevLink.href === next.url.href) {
      // go to previous project
      await fadeOut($$("[data-fade], [data-fade-default]", current.container).reverse(), "right");
    } else if ($nextLink && $nextLink.href === next.url.href) {
      // go to next project
      await fadeOut($$("[data-fade], [data-fade-default]", current.container).reverse(), "left");
    } else {
      await fadeOut($$("[data-fade], [data-fade-default]", current.container).reverse());
    }
  },

  async afterLeave({ next }) {
    $$("[data-fade], [data-fade-default]", next.container).forEach((item) => item.style.opacity = 0);

    if (window.scrollY > 0) {
      await new Promise((resolve) =>
        zenscroll.toY(0, 0, () => resolve())
      );
    }
  },

  async enter({ current, next }) {
    const $prevLink = $("a[rel=prev]", current.container);
    const $nextLink = $("a[rel=next]", current.container);

    fadeIn($$(".js-project-nav-link", next.container))

    if ($prevLink && $prevLink.href === next.url.href) {
      // go to previous project
      await fadeIn($$("[data-fade], [data-fade-default]", next.container), "left");
    } else if ($nextLink && $nextLink.href === next.url.href) {
      // go to next project
      await fadeIn($$("[data-fade], [data-fade-default]", next.container), "right");
    } else {
      await fadeIn($$("[data-fade], [data-fade-default]", next.container));
    }
  }
};
