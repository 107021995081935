import { $$ } from "../utils/query-selector.js"
import { fadeIn, fadeOut } from "../utils/animations.js"

export default {
  name: "home-to-project-transition",

  from: {
    namespace: "home",
  },

  async leave({ current }) {
    await fadeOut($$("[data-fade], [data-fade-default], [data-current='true'] .js-projects-scroller__thumbnail, .c-projects-scroller__title, .c-projects-scroller__title-ghost", current.container).reverse())
  },

  async afterLeave({ next }) {
    $$("[data-fade], [data-fade-default]", next.container).forEach((item) => item.style.opacity = 0)
    window.scrollTo(0, 0);
  },

  async enter({ next }) {
    return fadeIn($$("[data-fade], [data-fade-default]", next.container), "up")
  }
};
