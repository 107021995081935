import anime from "animejs";
import config from "../config.js";

const OFFSET_X = 100;
const OFFSET_Y = 30;

export async function animateContainerHeight(from, to) {
  const { height: oldHeight } = from.getBoundingClientRect();
  const { height: newHeight } = to.getBoundingClientRect();

  await anime({
    targets: [from, to],
    duration: config.transitionDuration,
    easing: "easeInOutQuad",
    height: [oldHeight, newHeight]
  }).finished;

  to.style.removeProperty("height");
}

export function fadeOut(targets, direction = "none") {
  let translateX = 0;
  let translateY = 0;

  if (direction === "right") {
    translateX = [0, OFFSET_X];
  } else if (direction === "left") {
    translateX = [0, -1 * OFFSET_X];
  } else if (direction === "down") {
    translateY = [0, OFFSET_Y];
  } else if (direction === "up") {
    translateY = [0, -1 * OFFSET_Y];
  }

  return anime({
    targets,
    duration: config.transitionDuration,
    easing: "easeOutQuad",
    opacity: [1, 0],
    translateX,
    translateY,
    delay: anime.stagger(config.transitionStagger),
  }).finished;
}

export function fadeIn(targets, direction = "none") {
  let translateX = 0;
  let translateY = 0;

  if (direction === "right") {
    translateX = [OFFSET_X, 0];
  } else if (direction === "left") {
    translateX = [-1 * OFFSET_X, 0];
  } else if (direction === "up") {
    translateY = [OFFSET_Y, "0"];
  }

  return anime({
    targets,
    duration: config.transitionDuration,
    easing: "easeOutQuad",
    opacity: [0, 1],
    translateX,
    translateY,
    delay: anime.stagger(config.transitionStagger),
  }).finished.then(() => {
    targets.forEach((item) => {
      item.style.removeProperty("opacity");
      item.style.removeProperty("transform");
    });
  });
}
