import { $, $$ } from '../utils/query-selector.js'
import ProjectsScroller from '../components/projects-scroller.js'

let scrollers = []
let oldScrollers = []

export default {
  namespace: 'home',
  beforeEnter({ next }) {
    oldScrollers = scrollers
    scrollers = []

    const $scroller = $('.js-projects-scroller', next.container)

    if (!$scroller) {
      return
    }

    scrollers.push(new ProjectsScroller($scroller))
  },
  beforeLeave() {
    for (const g of scrollers) {
      g.freeze()
    }

    scrollers = []
  },
  after() {
    for (const g of oldScrollers) {
      g.destroy()
    }

    scrollers = []
  },
};
