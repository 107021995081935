import Flickity from 'flickity'
import 'flickity-fade'

import Counter from '../components/counter.js'
import PhotoIndex from '../components/photo-index.js'
import cursor from '../components/cursor.js'
import { $, $$ } from '../utils/query-selector.js'
import debounce from '../utils/debounce.js'
import throttle from '../utils/throttle.js'

let hintDismissed = false

export default class Project {
  constructor($el) {

    this.$el = $el
    this.$galleryEL = $('.js-project__gallery', $el)
    this.$$galleryImages = $$('img', this.$galleryEL)
    this.$swipeHint = $('.js-project__swipe-hint', $el)

    if (hintDismissed) {
      this.hideSwipeHint()
    } else if (this.$swipeHint) {
      setTimeout(() => {
        this.$swipeHint.hidden = false
        setTimeout(() => {
          this.$swipeHint.hidden = true
        }, 3000)
      }, 250)

    }

    this.flkty = new Flickity(this.$galleryEL, {
      pageDots: false,
      adaptiveHeight: false,
      watchCSS: false,
      setGallerySize: false,
      dragThreshold: 1,
      prevNextButtons: false,
      fade: true,
      selectedAttraction: 0.01,
      friction: 0.15,
    })

    this.initInteractions()
    this.initCounter()
    this.initPhotoIndex()
  }

  setSpec() {
    this.spec = this.$galleryEL.getBoundingClientRect()
  }

  hideSwipeHint() {
    if (this.$swipeHint) {
      this.$swipeHint.hidden = true
      hintDismissed = true
    }
  }

  destroy() {
    this.togglePhotoIndex(false)
    this.flkty.destroy()
    this.hideSwipeHint()
    window.removeEventListener('resize', this.updateSpec)
  }

  initCounter() {
    const $counterEl = $('.js-project__counter', this.$el)

    if (!$counterEl) {
      return
    }

    this.counter = new Counter($counterEl, {
      min: 1,
      max: this.flkty.slides.length,
      value: 1,
    })

    this.flkty.on('change', () => this.counter.update(this.flkty.selectedIndex + 1))
  }

  initInteractions() {
    if (this.$$galleryImages.length <= 1) {
      return
    }

    // handle clicks vs dragging
    this.setSpec()
    this.updateSpec = debounce(this.setSpec.bind(this))
    window.addEventListener('resize', this.updateSpec)
    this.flkty.on('staticClick', (event, pointer) => {
      if (pointer.pageX >= this.spec.left + this.spec.width / 2) {
        this.flkty.next()
      } else {
        this.flkty.previous()
      }
    })

    // prev/next buttons visibility based on mouse position
    this.lastCursor = null
    this.lastMouseEvent = null
    this.isDragging = false

    this.flkty.on('dragStart', () => {
      this.isDragging = true
      this.hideSwipeHint()
    })
    this.flkty.on('dragEnd', () => {
      this.isDragging = false
    })

    this.handleMouseMove = throttle((e) => {
      this.lastMouseEvent = e.clientX ? e : this.lastMouseEvent

      if (!this.lastMouseEvent) {
        return
      }

      let newMousePosition = this.isDragging
        ? 'move'
        : this.lastMouseEvent.clientX >= this.spec.left + this.spec.width / 2
        ? 'next'
        : 'prev'

      if (newMousePosition === 'prev' && this.flkty.selectedIndex === 0) {
        newMousePosition = 'first'
      } else if (
        newMousePosition === 'next' &&
        this.flkty.selectedIndex === this.flkty.slides.length - 1
      ) {
        newMousePosition = 'last'
      }

      if (newMousePosition !== this.lastCursor) {
        this.$galleryEL.setAttribute('data-cursor', newMousePosition)
        cursor.update()
      }

      this.lastCursor = newMousePosition
    }, 20)

    this.$galleryEL.addEventListener(
      'mouseenter',
      () => {
        this.$galleryEL.addEventListener('mousemove', this.handleMouseMove)
      },
      { passive: true }
    )

    this.$galleryEL.addEventListener(
      'mouseleave',
      () => {
        this.$galleryEL.removeEventListener('mousemove', this.handleMouseMove)
        this.$galleryEL.removeAttribute('data-cursor')
        this.lastCursor = null
      },
      { passive: true }
    )

    this.flkty.on('pointerMove', this.handleMouseMove)
    this.flkty.on('dragMove', this.handleMouseMove)
    this.flkty.on('change', this.handleMouseMove)
    this.flkty.on('settle', this.handleMouseMove)
  }

  initPhotoIndex() {
    const $indexEl = $(".js-photo-index", this.$el)

    if (!$indexEl) {
      return
    }

    this.photoIndex = new PhotoIndex($indexEl)

    $indexEl.addEventListener('index.toggle', ({detail: open }) => {
      this.$el.classList.toggle("is-photo-index-open", open)
    })

    $indexEl.addEventListener('index.select', ({detail: index}) => {
      console.log("index", index);
      this.flkty.select(index, false, false)
      this.$galleryEL.focus()
    })

    this.flkty.on('change', () => {
      this.photoIndex.select(this.flkty.selectedIndex)
      this.hideSwipeHint()
    })
    this.photoIndex.select(this.flkty.selectedIndex)
  }

  togglePhotoIndex(force) {
    if (!this.index) {
      return
    }

    return this.index.toggle(force)
  }
}
