import { $, $$ } from "../utils/query-selector.js";
import { fadeIn, fadeOut } from "../utils/animations.js";
import zenscroll from "zenscroll";
import config from "../config.js";

export default {
  name: "default-transition",

  async leave({ current }) {
    await fadeOut($$("[data-fade], [data-fade-default]", current.container).reverse(), "down");

    const $$nav = $$(".js-project-nav", current.container)
    if ($$nav.length > 0) fadeOut($$nav)
  },

  async afterLeave({ next }) {
    $$("[data-fade], [data-fade-default]", next.container).forEach((item) => item.style.opacity = 0);

    if (window.scrollY > 0) {
      await new Promise((resolve) =>
        zenscroll.toY(0, config.scrollDuration, () => resolve())
      );
    }
  },

  async enter({ next }) {
    const $$nav = $$(".js-project-nav", next.container)
    $$nav.forEach($el => $el.style.opacity = 0)
    if ($$nav.length > 0) fadeIn($$nav)

    return fadeIn($$("[data-fade], [data-fade-default]", next.container), "up");
  }
};
