import { $, $$ } from '../utils/query-selector.js'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import config from '../config.js'
import hotkeys from 'hotkeys-js'
import zenscroll from 'zenscroll'

export default class PhotoIndex {
  constructor($el) {
    this.isIndexAnimated = false
    this._closeIndexCallback = () => this.toggle(false)

    this.$el = $el;
    this.$indexToggle = $('.js-photo-index__toggle', $el)
    this.$indexPopup = $('.js-photo-index__popup', $el)
    this.$indexContent = $('.js-photo-index__content', $el)
    this.$$indexItems = $$('li', this.$indexContent)
    this.$$indexImages = $$('img', this.$index)


    this.$indexToggle.addEventListener('click', () => this.toggle());
    this.$indexPopup.addEventListener('click', (e) => {
      const $button = e.target.closest('button');

      if (!$button) {
        return;
      }

      this.toggle(false)
      this.$el.dispatchEvent(new CustomEvent('index.select', { detail: parseInt($button.dataset.select, 10) }));
    })
  }

  async toggle(force) {
    if (this.isIndexAnimated) {
      return
    }

    const oldState = this.$indexToggle.getAttribute('aria-expanded') === 'true'
    let state = typeof force !== 'undefined' ? force : !oldState

    if (state === oldState) {
      return
    }

    this.isIndexAnimated = true

    if (state === true) {
      // open index
      let $current = this.$$indexItems.find(($item) => $item.classList.contains('is-current'))
      if ($current) {
        $current.scrollIntoView()
      }

      if (window.scrollY > 0) {
        await new Promise((resolve) =>
          zenscroll.toY(0, config.scrollDuration, () => resolve())
        )
      }

      disableBodyScroll(this.$indexContent)
      hotkeys('esc', this._closeIndexCallback)
    } else {
      // close index
      enableBodyScroll(this.$indexContent)
      hotkeys.unbind('esc', this._closeIndexCallback)
    }

    this.$indexToggle.setAttribute('aria-expanded', state.toString())
    this.$el.classList.toggle('is-index-open', state)
    this.$el.dispatchEvent(new CustomEvent('index.toggle', { detail: state }))
    this.isIndexAnimated = false
  }

  select(index) {
    this.$$indexItems.forEach((v, i) => {
      v.classList.toggle('is-current', i === index)
    })
  }
}
