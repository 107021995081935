import "../css/index.css";

import "lazysizes"
import "zenscroll"

import "./utils/vh-fix.js"

import cursor from "./components/cursor.js"
import menu from "./components/menu.js"
import homeView from "./views/home.js"
import workView from "./views/work.js"
import aboutView from "./views/about.js"
import projectView from "./views/project.js"

import defaultTransition from "./transitions/default.js"
import homeToTransition from "./transitions/home-to.js"
import workToWorkTransition from "./transitions/work-to-work.js"
import projectToProjectTransition from "./transitions/project-to-project.js"

import barba from "@barba/core"
import zenScroll from "zenscroll"

if (history.scrollRestoration) {
  history.scrollRestoration = "manual"
}

document.body.addEventListener("click", (e) => {
  const link = e.target.closest('a[href*="#"]');

  if (!link) {
    return
  }

  if (
    window.location.host !== link.host ||
    window.location.pathname !== link.pathname
  ) {
    return
  }

  const target = document.getElementById(link.hash.replace(/^#/, ""))

  if (target !== null) {
    e.preventDefault()
    zenScroll.to(target, 500, () => {
      cursor.update()
    })

    if (link.hasAttribute("data-no-history") === false) {
      history.pushState({}, "", link.href)
    }
  }
})

barba.hooks.beforeLeave(() => cursor.load(true))
barba.hooks.after(() => cursor.load(false))
barba.hooks.leave((data) => menu.updateBeforeTransition(data))

barba.init({
  // debug: true,
  preventRunning: true,
  prevent: ({ el }) => el.classList && el.classList.contains("js-no-barba"),

  transitions: [
    defaultTransition,
    homeToTransition,
    workToWorkTransition,
    projectToProjectTransition,
  ],

  views: [
    homeView,
    workView,
    aboutView,
    projectView,
  ],
})

document.addEventListener("lazybeforeunveil", ({ target }) => {
  target.classList.add("is-loaded")
})
