// https://developer.mozilla.org/en-US/docs/Web/API/Document/elementFromPoint
const POINTER_EVENTS = [
  "mousemove",
  "mouseup",
  "pointermove",
  "pointerup"
];

const POINTER_EVENT_OPTIONS = {
  passive: true,
  capture: true
};

class Cursor {
  constructor() {
    this._init = false
    this.animFrame = null
    this.lastEvent = null

    window.addEventListener('touchstart', this.setTouch.bind(this))

    for (let eventName of POINTER_EVENTS) {
      window.addEventListener(eventName, this.init.bind(this), Object.assign({ once: true }, POINTER_EVENT_OPTIONS))
    }
  }

  init(e) {
    if (this._init) {
      return
    }

    // Cursor DOM
    this.$cursor = document.createElement("div")
    this.$cursor.className = "c-cursor"
    this.$inner = document.createElement("div")
    this.$inner.className = "c-cursor__inner"
    this.$cursor.appendChild(this.$inner)
    document.body.appendChild(this.$cursor)

    // Events
    for (let eventName of POINTER_EVENTS) {
      window.addEventListener(eventName, this.update.bind(this), POINTER_EVENT_OPTIONS)
    }

    document.documentElement.classList.add("has-custom-cursor")
    this._init = true;
    this.update(e);
  }

setTouch() {
    document.documentElement.classList.add("is-touch")
  }

  update(e) {
    if (typeof e !== "undefined") {
      this.lastEvent = e
    }

    if (!this.lastEvent) {
      return
    }

    // Update cursor position
    const { clientX, clientY } = this.lastEvent
    this.$cursor.setAttribute("style", `--x: ${clientX}px; --y: ${clientY}px;`)

    // Update cursor style only on next animation frame for better performance
    // and because slight delay is not that noticable.
    cancelAnimationFrame(this.animFrame);
    this.animFrame = requestAnimationFrame(() => {
      const target = e ? this.lastEvent.target : document.elementFromPoint( clientX, clientY )
      const $el = target.nodeType === Node.ELEMENT_NODE ? (target.matches("a,button,[data-cursor]") ? target : target.closest("a,button,[data-cursor]")) : null

      if ($el) {
        this.set("hover", true)
        this.set("prev", $el.dataset.cursor == "prev")
        this.set("next", $el.dataset.cursor == "next")
        this.set("down", $el.dataset.cursor == "down")
        this.set("first", $el.dataset.cursor == "first")
        this.set("last", $el.dataset.cursor == "last")
        this.set("move", $el.dataset.cursor == "move")
      } else {
        this.set("hover", false)
        this.set("prev", false)
        this.set("next", false)
        this.set("down", false)
        this.set("first", false)
        this.set("last", false)
        this.set("move", false)
      }
    })
  }

  load(on = true) {
    this.$cursor.classList[on ? "add" : "remove"]("is-loading")
  }

  set(type, on = true) {
    this.$cursor.classList[on ? "add" : "remove"](`is-${type}`)
  }
}


if (!window.__customCursor) {
  window.__customCursor = new Cursor();
}

export default window.__customCursor;
