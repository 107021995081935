import anime from "animejs";

import { $, $$ } from "../utils/query-selector.js";
import config from "../config.js";
import { animateContainerHeight, fadeIn, fadeOut } from "../utils/animations.js";

export default {
  name: "work-to-work",
  sync: true,

  from: {
    namespace: "work",
  },
  to: {
    namespace: "work",
  },

  async enter({ current, next }) {
    // Filter menu
    // const $currentCategories = $(".js-work__categories", current.container);
    // const $nextCategories    = $(".js-work__categories", next.container);
    // const $svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    // $svg.setAttribute("role", "presentation");
    // const $path = document.createElementNS("http://www.w3.org/2000/svg", "path");
    // $path.setAttributeNS(null, "fill", "none");
    // $svg.appendChild($path);
    // $nextCategories.appendChild($svg);

    // $currentCategories.classList.add("is-animated");
    // $nextCategories.classList.add("is-animated");

    // const $dot = document.createElement("div");
    // $dot.className = "c-work__categories-dot";
    // $nextCategories.appendChild($dot);

    // const shiftX = 0;
    // const shiftY = 0;

    // const $currentItem = $("[aria-current='true']", $currentCategories);
    // const $nextItem = $("[aria-current='true']", $nextCategories);
    // const parentSpec = $currentItem.offsetParent && $currentItem.offsetParent.getBoundingClientRect() || {top: 0, left: 0};
    // const currentSpec = $currentItem.getBoundingClientRect();
    // const nextSpec = $nextItem.getBoundingClientRect();

    // $dot.style.setProperty("top", currentSpec.height / 2 - 2 + "px");

    // const x0 = currentSpec.left - parentSpec.left;
    // const x1 = nextSpec.left - parentSpec.left
    // const y0 = currentSpec.top - parentSpec.top;
    // const y1 = nextSpec.top - parentSpec.top
    // const r = 20;

    // $path.setAttributeNS(null, "d", [
    //   `M ${x0 + shiftX} ${y0 + shiftY}`,
    //   (y1 > y0) ? `v ${y1 - y0}`: null,
    //   (x0 !== x1) ? `a ${r} ${r} 90,0,${x1 > x0 ? 0 : 1} ${x1 > x0 ? r : -r} ${r}` : null,
    //   (x0 !== x1) ? `h ${x1 - x0 + (x1 > x0 ? (-2 * r) : (2 * r))}` : null,
    //   (x0 !== x1) ? `a ${r} ${-1 * r} 90,0,${x1 > x0 ? 0 : 1} ${x1 > x0 ? r : -r} ${-1 * r}` : null,
    //   (y1 < y0) ? `v ${y1 - y0}`: null,
    // ].filter((v) => !!v).join(" "));

    // const motionPath = anime.path($path);
    // const filterAnimation = anime({
    //   targets: $dot,
    //   translateX: motionPath("x"),
    //   translateY: motionPath("y"),
    //   duration: config.transitionDuration,
    //   easing: "easeOutQuad",
    // }).finished;

    // Thumbnail transitions
    let $$currentProjects = $$(".js-project-grid__item", current.container);
    let $$nextProjects    = $$(".js-project-grid__item", next.container);
    let $$removedProjects = $$currentProjects.filter((item) => $$nextProjects.find((n) => n.dataset.projectId === item.dataset.projectId) === undefined);
    let $$stayingProjects = $$currentProjects.filter((item) => $$nextProjects.find((n) => n.dataset.projectId === item.dataset.projectId) !== undefined);
    let $$newProjects = $$nextProjects.filter((item) => $$currentProjects.find((n) => n.dataset.projectId === item.dataset.projectId) === undefined);

    let containerTransition = animateContainerHeight(current.container, next.container);

    $(".js-project-grid", current.container).classList.add("is-animated");

    $$nextProjects.forEach((item) => item.style.opacity = "0");

    let stayingProjectsTl = anime.timeline({
      duration: config.transitionDuration * 1.5,
      easing: "easeInOutQuad",
    });

    let $$nextProjectsReplacingStayingProjects = [];

    $$stayingProjects.forEach((item) => {
      let $nextProject = $(`[data-project-id="${item.dataset.projectId}"]`, next.container);
      const currentProductSpec = item.getBoundingClientRect();
      const nextProductSpec = $nextProject.getBoundingClientRect();
      const x = nextProductSpec.x - currentProductSpec.x;
      const y = nextProductSpec.y - currentProductSpec.y;

      stayingProjectsTl = stayingProjectsTl.add({
        targets: item,
        translateX: `${x}px`,
        translateY: `${y}px`,
      }, 0);

      $$nextProjectsReplacingStayingProjects.push($nextProject);
    });

    await Promise.all([/*filterAnimation,*/ fadeOut($$removedProjects, "down"), stayingProjectsTl.finished]);

    $$nextProjectsReplacingStayingProjects.forEach((item) => item.style.opacity = "1");

    await fadeIn($$newProjects, "up");
    await containerTransition;

    // $dot.remove();
    // $nextCategories.classList.remove("is-animated");
  },
};
