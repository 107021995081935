import { range } from "underscore";

export default class Counter {
  defaults() {
    return {
      min: 0,
      max: 10,
      minLength: null,
      value: 0,
    }
  }

  constructor($el, options = {}) {
    this.$el = $el;
    this.options = Object.assign(this.defaults(), options);
    this.counterLength = this.options.minLength !== null
      ? this.options.minLength
      : Math.max(this.options.min.toString().length, this.options.max.toString().length);

    this.$$digits = [];

    for (let i = 0; i < this.counterLength; i++) {
      const $digit = document.createElement("div");
      $digit.className = "c-counter__digit";
      $digit.innerHTML = "<span>" + range(0, 10).join("</span><span>") + "</span>";
      this.$$digits.push($digit);
      this.$el.appendChild($digit);
    }

    this.$el.classList.add("c-counter");
    this.update(this.options.value);
  }

  update(number) {
    const digits = number.toString().padStart(this.counterLength, "0");
    for (let i = 0; i < digits.length; i++) {
      this.$$digits[i].style.setProperty("--num", digits[i]);
    }
  }
}
