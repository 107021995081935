import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import Headroom from 'headroom.js'

import { $, $$ } from '../utils/query-selector.js'
import { documentFromHTML } from '../utils/dom.js'

const MENU_BREAKPOINT = '(min-width: 60em)' // 960px

class Theme {
  constructor() {
    this.mediaQuery = window.matchMedia
      ? window.matchMedia('(prefers-color-scheme: dark)')
      : {
        matches: false,
        addEventListener: () => {},
      }

    this.mediaQuery.addEventListener('change', () => this.update())

    this.update()

    // init in next frame
    requestAnimationFrame(() => {
      document.documentElement.classList.add('is-theme-init')
    })
  }

  get defaultTheme() {
    return this.mediaQuery.matches ? 'dark' : 'light'
  }

  get theme() {
    return this.userTheme || this.defaultTheme
  }

  setTheme(theme) {
    sessionStorage.setItem('color-theme', theme)
    this.update()
  }

  toggle() {
    this.setTheme(this.theme === 'light' ? 'dark' : 'light')
  }

  update() {
    document.documentElement.dataset.theme = this.theme
  }

  get userTheme() {
    return sessionStorage.getItem('color-theme')
  }
}

class Menu {
  constructor($el) {
    this.$el = $el
    this.headroom = new Headroom(this.$el, {
      offset : 60,
      classes: {
        initial : 'is-init',
        // when scrolling up
        pinned : 'is-pinned',
        // when scrolling down
        unpinned : 'is-unpinned',
        // when above offset
        top : 'is-top',
        // when below offset
        notTop : 'is-not-top',
        // when at bottom of scroll area
        bottom : 'is-bottom',
        // when not at bottom of scroll area
        notBottom : 'is-not-bottom',
        // when frozen method has been called
        frozen: 'is--frozen',
      }
    });


    this.$toggleButton = $('.js-menu__toggle', this.$el)
    this.$popup = $('.js-menu__popup', this.$el)
    this.$list = $('.js-menu__list', this.$el)
    this.$links = $$('.js-menu__link', this.$el)

    this.$toggleButton.addEventListener('click', this.toggle.bind(this))

    this.mediaQuery = matchMedia(MENU_BREAKPOINT)
    this.mediaQuery.addEventListener('change', this.setDesktopOrMobileMenu.bind(this))
    this.setDesktopOrMobileMenu()

    $('.js-menu__backdrop', $el).addEventListener('click', () => this.toggle(false))

    // Color scheme
    this.theme = new Theme()
    $('.js-menu__theme-toggle', $el).addEventListener('click', () => this.theme.toggle())

    this.headroom.init()

    requestAnimationFrame(() => this.$el.classList.add('is-init'))
  }

  isDesktop() {
    return this.mediaQuery.matches
  }

  toggle(force) {
    const oldState = this.$toggleButton.getAttribute('aria-expanded') === 'true'
    const newState = typeof force === 'boolean' ? force : !oldState

    if (oldState === newState) {
      return
    }

    if (newState && !this.isDesktop()) {
      scrollTo(0, 0)
    }

    this.$toggleButton.setAttribute('aria-expanded', newState.toString())

    this.$el.classList.toggle('is-open', newState)

    if (newState) {
      disableBodyScroll(this.$popup, {
        reserveScrollBarGap: true,
      })
    } else {
      enableBodyScroll(this.$popup)
    }
  }

  setDesktopOrMobileMenu() {
    if (this.isDesktop()) {
      this.toggle(false)
    }
  }

  async updateBeforeTransition({ next }) {
    this.toggle(false);
    const $nextMenu = $('.js-menu', documentFromHTML(next.html))

    // update curernt menu item
    const $$currentLinks = $$('.js-menu__link', this.$el)
    for (const $link of $$currentLinks) {
      const $nextLink = $(`a[href='${$link.getAttribute('href')}']`, $nextMenu)
      if (!$nextLink) continue
      $link.setAttribute('aria-current', $nextLink.getAttribute('aria-current'))
    }

    // update language toggle link
    const $currentLanguages = $('.js-menu__languages', this.$el)
    const $nextLanguages = $('.js-menu__languages', $nextMenu)
    $currentLanguages.replaceWith($nextLanguages)
  }
}

export default new Menu($('.js-menu'))
